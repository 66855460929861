import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import yildiz1 from "../../assets/img/yildiz-entegre-1.png"
import yildiz2 from "../../assets/img/yildiz-entegre-2.png"

export default function YildizEntegre() {
    const { t } = useTranslation()
    return (
        <Layout>
            <SEO title="Yıldız Entegre" />
            <div className="projeler-container page">
                <div className="ic-proje">
                    <img src={yildiz1} alt="" className="proje-icGorsel" />
                    <div className="proje-kunye">
                        <span>{t("project-brand")}</span>: {t("yildiz-entegre")}
                        <br />
                        <span>{t("project-sector")}</span>: {t("sanayi")}
                        <br />
                        <span>{t("project-subject")}</span>: {t("logonun-yorumu")}
                        <br />
                        <span>{t("project-artwork")}</span>: {t("allah-cc-tekrari")}
                    </div>
                    <img src={yildiz2} alt="" className="proje-icGorsel" />
                </div>
            </div>
        </Layout>
    )
}
